
import Vue from "vue";

export default Vue.extend({
  name: "SessionCertificateData",
  components: {},
  props: {
    certificate: Object
  }
});
